<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="detail">
            <div class="top">
                <img class="images" :src="detail.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ detail.name }}</div>
                    <div class="desc" @click="$router.push({ path: '/communityMember', query: { id: detail.id } })">
                        <span>成员</span>
                        <div class="imgs">
                            <img v-for="(item, index) in userAvatar" :key="index" :src="item" alt="" />
                        </div>
                        <img src="@/assets/images/Group882.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="details">成员:{{ detail.peopleNum || 0 }}&nbsp;&nbsp;&nbsp;讨论:{{ detail.messageNum || 0 }}&nbsp;&nbsp;&nbsp;创建时间:{{ detail.createTime }}</div>
            <div class="desc">{{ detail.briefIntroduction }}</div>
            <div class="butts">
                <div v-if="detail.joinCommunity" @click="getOut" class="butt">退出社群</div>
                <div v-else @click="joinCommunity" class="butt">加入社群</div>
                <div class="butt" @click="ruleVisible = true">发帖</div>
            </div>
        </div>
        <div class="list1">
            <div class="item" @click="$router.push({ path: '/communityPostsDetail', query: { id: item.id } })" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <div class="left">
                        <img :src="item.avatar ? item.avatar : require('../../assets/images/default_avatar.jpeg')" alt="" />
                        <span>{{ item.nickName }}</span>
                        <span>{{ item.createTime }}</span>
                    </div>
                    <div class="right">
                        <div class="items" @click.stop="collection(item)">
                            <!--                         eslint-disable-next-line -->
                            <img :src="item.collectionId ? require('../../assets/images/Frame-32.png') : require('../../assets/images/Frame-31.png')" />
                            <!--<img src="@/assets/images/Frame-31.png" alt="" />-->
                            <span>{{ item.collectionCount || 0 }}</span>
                        </div>
                        <div class="items">
                            <img src="@/assets/images/Group622.png" alt="" />
                            <span>{{ item.commentCount || 0 }}</span>
                        </div>
                        <div class="items" @click.stop="praise(item)">
                            <!-- <img src="@/assets/images/Frame-53.png" alt="" />-->
                            <!--                          eslint-disable-next-line-->
                            <img :src="item.praiseId ? require('../../assets/images/Frame-30.png') : require('../../assets/images/Frame-29.png')" />
                            <span>{{ item.praiseCount || 0 }}</span>
                        </div>
                    </div>
                </div>
                <div class="content">{{ item.content }}</div>
                <div class="img">
                    <!--  eslint-disable-next-line -->
                    <div class="" v-for="(t, i) in item.picUrl" :key="i" @click.stop="() => {}">
                        <el-image style="width: 100%; height: 130px" :src="t" :preview-src-list="item.picUrl"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getCommunityPostsList" @current-change="getCommunityPostsList" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
        <!--创建社群-->
        <el-dialog title="发帖" :visible.sync="ruleVisible" width="40%" class="dialog" append-to-body>
            <div class="item">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">内容：</span>
                </div>
                <el-input v-model="synopsis" :rows="5" type="textarea" placeholder="请输入帖子内容"></el-input>
            </div>
            <div class="item uploads">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">图片：</span>
                </div>
                <el-upload :class="{ hide: hide }" action="#" :disabled="fileList.length >= 2" :http-request="upDate" :file-list="fileList" list-type="picture-card" :limit="9" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="ruleVisible = false">取 消</el-button>
                <el-button type="primary" @click="addCommunityPosts">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" append-to-body class="dialog1">
            <img width="50%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import uploadFile from '@/utils/oss/ossClient';
export default {
    name: 'detail',
    data() {
        return {
            synopsis: '',
            tabs: 0,
            limit: 12,
            page: 1,
            total: 0,
            list: [],
            imgList: [],
            userAvatar: [],
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '社群',
                    to: { path: '/communityList' },
                },
                {
                    title: '社群详情',
                },
            ],
            detail: {},
            ruleVisible: false,
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            hide: false,
        };
    },
    watch: {
        fileList(val) {
            // console.log(val);
        },
    },
    created() {
        this.getCommunityDetail();
        this.initCommunityUsers();
    },
    methods: {
        getData() {
            console.log(this.page, this.limit);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            console.log(fileList);
            this.fileList = fileList;
        },
        getCommunityDetail() {
            this.$hao.getCommunityDetail(this.$route.query.id).then((res) => {
                this.detail = res.data;
                this.getCommunityPostsList();
            });
        },
        initCommunityUsers() {
            this.$hao.getCommunityUsers({ communityId: this.$route.query.id, pageNum: 1, pageSize: 3 }).then((res) => {
                this.userAvatar = res.rows.map((ele) => {
                    return ele.avatar;
                });
            });
        },
        getCommunityPostsList() {
            let data = {
                pageNum: this.page,
                pageSize: this.limit,
                communityId: this.$route.query.id,
                status: 1,
            };
            this.$hao.getCommunityPostsList(data).then((res) => {
                console.log(res);
                let arr = res.rows.map((item) => {
                    item.picUrl = item.picUrl.split(',');
                    return item;
                });
                this.list = arr;
                this.total = res.total;
                if (this.page == 1) {
                    this.imgList = res.rows.splice(0, res.rows.length >= 3 ? 3 : res.rows.length);
                }
            });
        },
        joinCommunity() {
            this.$hao.joinCommunity(this.$route.query.id).then((res) => {
                if (res.code === 200) {
                    this.$message.success('加入成功');
                    this.getCommunityDetail();
                } else {
                    // this.$message.error(res.msg);
                }
            });
        },
        upDate(e) {
            console.log(e);

            uploadFile(e).then((res) => {
                this.picUrl = res[0];
                this.fileList.push({ uid: e.file.uid, url: res[0] });
            });
        },
        addCommunityPosts() {
            if (!this.synopsis) {
                this.$message.error('请输入帖子内容');
                return;
            }
            if (this.fileList.length === 0) {
                this.$message.error('请上传图片');
                return;
            }
            let data = {
                communityId: this.$route.query.id,
                content: this.synopsis,
                picUrl: this.fileList.map((item) => item.url).join(','),
            };
            this.$hao.addCommunityPosts(data).then((res) => {
                if (res.code === 200) {
                    this.$message.success('发帖成功');
                    this.ruleVisible = false;
                    this.getCommunityPostsList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 收藏
        collection(item) {
            if (item.collectionId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 4 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('收藏成功');
                        item.collectionId = res.data.id;
                        item.collectionCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.collectionId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消收藏成功');
                        item.collectionId = null;
                        item.collectionCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 点赞
        praise(item) {
            if (item.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: item.id, type: 8 }).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('点赞成功');
                        item.praiseId = res.data.id;
                        item.praiseCount++;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$hao.delPraiseCollection(item.praiseId).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('取消点赞成功');
                        item.praiseId = null;
                        item.praiseCount--;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        getOut() {
            this.$hao.exitCommunity(this.detail.id).then((res) => {
                if (res.code == 200) {
                    this.$message.success('退出社群成功');
                    // item.praiseId = null;
                    // item.praiseCount--;
                    setTimeout(() => {
                        this.$router.go(1);
                    }, 2000);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.detail {
    width: 100%;
    background: #6e85a6;
    padding: 24px;
    color: #ffffff;
    .top {
        display: flex;
        align-items: center;
        .info {
            margin-left: 8px;
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
                color: #ffffff;
            }
            .desc {
                display: flex;
                align-items: center;
                margin-top: 20px;
                color: #ffffff;
                span {
                    font-size: 14px;
                }
                .imgs {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: -12px;
                    }
                }
                img {
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                }
            }
        }
        .images {
            width: 80px;
            height: 80px;
            border-radius: 8px;
        }
    }
    .details {
        font-size: 14px;
        margin: 16px 0;
    }
    .desc {
        margin-bottom: 30px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.8);
    }
    .butts {
        display: flex;
        .butt {
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 2px;
            margin-right: 2px;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            &:nth-child(1) {
                background: rgba(255, 255, 255, 0.2);
                margin-right: 10px;
            }
            &:nth-child(2) {
                background: #eda200;
            }
        }
    }
}
.list1 {
    margin-top: 24px;
    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 12px;
        padding: 24px;

        .top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }

                span {
                    margin-right: 8px;
                    font-weight: 400;
                    font-size: 13px;
                    color: #333333;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                span:last-child {
                    color: #999999;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .items {
                    display: flex;
                    align-items: center;
                    margin-left: 24px;

                    img {
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                    }

                    span {
                        font-size: 14px;
                        line-height: 20px;
                        color: #797a87;
                    }
                }
            }
        }

        .content {
            padding: 12px 0;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
        }

        .img {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4px;
            width: 50%;
            img {
                width: 100%;
                height: 100px;
                margin-top: 4px;
            }
        }
    }
}

.dialog {
    padding-top: 8px;
    .item {
        display: flex;
        flex-direction: column;
        ::v-deep .el-input__inner {
            border-radius: 4px;
        }

        div {
            margin-bottom: 12px;
            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            img {
                width: 7px;
                height: 17px;
            }
        }

        .jifen {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #fd8f30;
        }
    }

    .uploads {
        ::v-deep .el-upload--picture-card {
            margin: 0 10px 10px 0;
            width: 76px;
            height: 76px;
            line-height: 86px;
        }
        ::v-deep .el-upload-list__item {
            width: 76px;
            height: 76px;
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409eff;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}
.dialog1 {
    text-align: center;
}

::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
